<template>
  <div class="home">
    <b-container>
      <b-row>
        <b-col>
          <b-card header="Auftrag" header-tag="header"
            ><NewBadge eol="2023-03-14"></NewBadge>
            <table>
              <tbody>
                <tr>
                  <td>mit fehlendem Umsatz</td>
                  <th>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ this.payPriceInfo.missingPrice
                    }}<ViewBadge target="#" />
                  </th>
                </tr>
                <tr>
                  <td>warte auf &Uuml;berweisung</td>
                  <th>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ this.payPriceInfo.missingUEB
                    }}<ViewBadge />
                  </th>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
        <b-col>
          <b-card header="Buchhaltung" header-tag="header"
            ><NewBadge eol="2023-03-14"></NewBadge>
            <table>
              <tbody>
                <tr>
                  <td>offene Rechnungen</td>
                  <th>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ this.accountingOpen.invoices_m
                    }}<ViewBadge target="" />
                  </th>
                </tr>
                <tr>
                  <td>offene Gutschriften</td>
                  <th>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ this.accountingOpen.crediting
                    }}<ViewBadge target="" />
                  </th>
                </tr>
                <tr>
                  <td>offene Kostenübernahmen</td>
                  <th>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ this.accountingOpen.invoices
                    }}<ViewBadge target="" />
                  </th>
                </tr>
                <tr>
                  <td>offene Stornorechnungen</td>
                  <th>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ this.accountingOpen.storno
                    }}<ViewBadge target="" />
                  </th>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col lg="7">
          <b-card header="Auftragsverteilung (Zentralen)" header-tag="header">
            <b-row>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMediatorRating()"
                  v-model="mediatorRatingOptions.type"
                  :options="options"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMediatorRating()"
                  v-if="mediatorRatingOptions.type == 'month'"
                  v-model="mediatorRatingOptions.month"
                  :options="months"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMediatorRating()"
                  v-if="mediatorRatingOptions.type != 'all'"
                  v-model="mediatorRatingOptions.year"
                  :options="years"
                ></b-select>
              </b-col>
            </b-row>
            <PieChart
              v-if="
                mediatorRating.data != undefined && mediatorRatingOptions.show
              "
              :chartData="mediatorRating"
            />
          </b-card> </b-col
        ><b-col lg="5">
          <b-card header="Auftragstypen (Verteilung)" header-tag="header">
            <b-row>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateTypeRating()"
                  v-model="typeRatingOptions.type"
                  :options="options"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateTypeRating()"
                  v-if="typeRatingOptions.type == 'month'"
                  v-model="typeRatingOptions.month"
                  :options="months"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateTypeRating()"
                  v-if="typeRatingOptions.type != 'all'"
                  v-model="typeRatingOptions.year"
                  :options="years"
                ></b-select>
              </b-col>
            </b-row>
            <PieChart
              v-if="typeRating.data != undefined && typeRatingOptions.show"
              :chartData="typeRating"
            />
          </b-card>
        </b-col> </b-row
      ><br />
      <b-row>
        <b-col md="4">
          <b-card header="Monteur Umsatz">
            <b-row>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMonteurRating()"
                  v-model="monteurRatingOptions.type"
                  :options="options"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMonteurRating()"
                  v-if="monteurRatingOptions.type == 'month'"
                  v-model="monteurRatingOptions.month"
                  :options="months"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMonteurRating()"
                  v-if="monteurRatingOptions.type != 'all'"
                  v-model="monteurRatingOptions.year"
                  :options="years"
                ></b-select>
              </b-col>
            </b-row>
            <b-card-body>
              <table class="table" v-if="monteurRatingOptions.show == true">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Umsatz</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="tableInfo"
                    v-for="monteur in monteure"
                    :key="monteur.name"
                  >
                    <td>{{ monteur.name }}</td>
                    <td>
                      {{
                        monteur.gesamtWert.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card header="Zentralen Umsatz">
            <b-row>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMediatorMoney()"
                  v-model="mediatorMoneyOptions.type"
                  :options="options"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMediatorMoney()"
                  v-if="mediatorMoneyOptions.type == 'month'"
                  v-model="mediatorMoneyOptions.month"
                  :options="months"
                ></b-select>
              </b-col>
              <b-col>
                <b-select
                  class="form-control"
                  @change="updateMediatorMoney()"
                  v-if="mediatorMoneyOptions.type != 'all'"
                  v-model="mediatorMoneyOptions.year"
                  :options="years"
                ></b-select>
              </b-col>
            </b-row>
            <b-card-body>
              <table class="table" v-if="mediatorMoneyOptions.show == true">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Umsatz</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="tableInfo"
                    v-for="mediator in mediatorMoney"
                    :key="mediator.mediator"
                  >
                    <td>{{ mediator.mediator }}</td>
                    <td>
                      {{
                        mediator.gesamtWert.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import NewBadge from "@/components/objcs/NewBadge.vue";
import LineChart from "@/components/objcs/LineChart.vue";
import PieChart from "@/components/objcs/PieChart.vue";
import ViewBadge from "@/components/objcs/ViewBadge.vue";

export default {
  name: "HomeView",
  components: {
    NewBadge,
    LineChart,
    PieChart,
    ViewBadge,
  },
  data: function () {
    return {
      payPriceInfo: {
        missingPrice: 0,
        missingUEB: 0,
      },

      accountingOpen: {
        invoices_m: 0,
        crediting: 0,
        invoices: 0,
        storno: 0,
      },

      typeRating: {},
      typeRatingOptions: {
        show: false,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        type: "month",
      },

      mediatorRating: {},
      mediatorRatingOptions: {
        show: false,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        type: "month",
      },

      mediatorMoney: {},
      mediatorMoneyOptions: {
        show: false,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        type: "month",
      },

      monteurRatingOptions: {
        show: false,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        type: "month",
      },

      options: [
        { value: "year", text: "Ansicht: Jahr" },
        { value: "month", text: "Ansicht: Monat" },
        { value: "all", text: "Ansicht: Gesamt" },
      ],
      years: [
        {
          value: new Date().getFullYear() - 1,
          text: new Date().getFullYear() - 1,
        },
        { value: new Date().getFullYear(), text: new Date().getFullYear() },
      ],
      months: [
        { value: 0, text: "Januar" },
        { value: 1, text: "Februar" },
        { value: 2, text: "März" },
        { value: 3, text: "April" },
        { value: 4, text: "Mai" },
        { value: 5, text: "Juni" },
        { value: 6, text: "Juli" },
        { value: 7, text: "August" },
        { value: 8, text: "September" },
        { value: 9, text: "Oktober" },
        { value: 10, text: "November" },
        { value: 11, text: "Dezember" },
      ],

      monteure: [],
    };
  },
  mounted() {
    this.$root.apiRequest("/stats/payPriceInfo", {}, (response) => {
      this.payPriceInfo = response.data.result;
    });

    this.$root.apiRequest("/stats/accounting", {}, (response) => {
      this.accountingOpen = response.data.result;
    });

    this.updateTypeRating();
    this.updateMediatorRating();
    this.updateMonteurRating();
    this.updateMediatorMoney();
  },
  methods: {
    updateTypeRating: function () {
      this.typeRatingOptions.show = false;

      this.$root.apiRequest(
        "/stats/typeRating",
        {
          type: this.typeRatingOptions.type,
          month: this.typeRatingOptions.month + 1,
          year: this.typeRatingOptions.year,
        },
        (response) => {
          this.typeRating = response.data.result;
          this.typeRatingOptions.show = true;
        }
      );
    },
    updateMediatorRating: function () {
      this.mediatorRatingOptions.show = false;
      this.$root.apiRequest(
        "/stats/mediatorRating",
        {
          type: this.mediatorRatingOptions.type,
          month: this.mediatorRatingOptions.month + 1,
          year: this.mediatorRatingOptions.year,
        },
        (response) => {
          this.mediatorRating = response.data.result;
          this.mediatorRatingOptions.show = true;
        }
      );
    },
    updateMonteurRating: function () {
      this.$root.apiRequest(
        "/stats/monteur",
        {
          type: this.monteurRatingOptions.type,
          month: this.monteurRatingOptions.month + 1,
          year: this.monteurRatingOptions.year,
        },
        (response) => {
          this.monteurRatingOptions.show = false;
          this.monteure = response.data.result;
          this.monteurRatingOptions.show = true;
        }
      );
    },
    updateMediatorMoney: function () {
      this.$root.apiRequest(
        "/stats/mediator",
        {
          type: this.mediatorMoneyOptions.type,
          month: this.mediatorMoneyOptions.month + 1,
          year: this.mediatorMoneyOptions.year,
        },
        (response) => {
          this.mediatorMoneyOptions.show = false;
          this.mediatorMoney = response.data.result;
          this.mediatorMoneyOptions.show = true;
        }
      );
    }
  },
};
</script>

<style scoped>
th {
  text-align: left;
}
.tableInfo {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}
.table {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
</style>