import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)

//Vue.prototype.$endpointServer = "https://test.iams.k10026.kf-servers.de/backend/";
Vue.prototype.$user = {
  id: -2,
  username: "Unbekanter Benutzer",
  admin: 1
}
Vue.prototype.$search = false;
Vue.prototype.$serverUrl = serverUrl;
Vue.prototype.$prod = false;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  data: {
    search: false,
    requestProcessing: {
      running: false,
      error: true,
      taskId: 0,
      serverAnswer: '_-_',
      serverUrl: ''
    }
  },
  methods: {
    apiRequest: function (endpoint, data, callbackSuccess = undefined, callbackFailure = undefined) {
      this.$root.requestProcessing.running = true;
      this.$root.requestProcessing.error = false;
      this.$root.requestProcessing.serverAnswer = "";
      this.serverUrl = serverUrl() + endpoint;

      axios.post(serverUrl() + endpoint, data, { withCredentials: true }).then((response) => {
        if (response.data.status != undefined) {
          if (response.data.status == "SUCCESS") {
            if (callbackSuccess != undefined) callbackSuccess(response);
            this.$root.requestProcessing.running = false;
            this.$root.requestProcessing.error = false;
          } else {
            if (callbackFailure != undefined) callbackFailure(response);
            this.$root.requestProcessing.error = true;
            this.$root.requestProcessing.serverAnswer = JSON.stringify(response.data);
          }
        } else {
          if (callbackFailure != undefined) callbackFailure(response);
          this.$root.requestProcessing.error = true;
          this.$root.requestProcessing.serverAnswer = JSON.stringify(response.data);
        }

      }).catch((reason) => {
        if (callbackFailure != undefined) callbackFailure(reason);

        this.$root.requestProcessing.error = true;
        this.$root.requestProcessing.serverAnswer = JSON.stringify(reason);
      }).finally(() => {

      });
    },
  }
}).$mount('#app')

function serverUrl() {
  if (window.location.href.includes("https://10.0.0.125")) {
    return "https://10.0.0.125:8808";
  }
  if (window.location.href.includes("https://test.iams.k10026.kf-servers.de")) {
    return "https://test.iams.k10026.kf-servers.de/test-backend";
  }
  if (window.location.href.includes("https://richter.myiams.de")) {
    return "https://richter.myiams.de/backend";
  }
}
